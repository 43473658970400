import "./Footer.css";

import { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Link } from 'react-router-dom';


const Footer = () => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    // event listener to close modal when clicked outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.className === "warranty-modal") {
                setShowModal(false);
            }
        }

        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="footer">
            <div className="content">
                <div className="col">
                    <div className="title">
                        <h2>River City Cabinets</h2>
                        <hr />
                    </div>
                    <p>We offer stunning cabinet designs, unbeatable prices and excellent customer service in Nampa Idaho and Boise Metropolitan Area.</p>
                    <div className="infos mobile">
                        <div className="info">
                            <Link to="mailto:rccsales208@gmail.com">
                                <i className="fa-solid fa-envelope">
                                        <FontAwesomeIcon icon={icon({name: 'envelope', style: 'solid'})} className='icon' />
                                </i>
                            </Link>
                            <h3><a href="mailto:rccsales208@gmail.com">rccsales208@gmail.com</a></h3>
                        </div>
                        <div className="info">
                            <Link to="tel:2085595302">
                                <i className="fa-solid fa-phone">
                                    <FontAwesomeIcon icon={icon({name: 'phone', style: 'solid'})} className='icon' />
                                </i>
                            </Link>
                            <h3><a href="tel:2085595302">208-559-5302</a></h3>
                        </div>
                        <div className="info">
                            <Link to="https://g.page/rivercitycabinetsidaho?share" target="_blank" rel="noreferrer">
                                <i className="fa-solid fa-map-pin">
                                    <FontAwesomeIcon icon={icon({name: 'map-pin', style: 'solid'})} className='icon' />
                                </i>
                            </Link>
                            <h3><a href="https://g.page/rivercitycabinetsidaho?share" target="_blank" rel="noreferrer">2603 Sundance Road, Suite 101, Nampa ID, 83651</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="subtitle">
                        <h2>Home</h2>
                        <hr />
                    </div>
                    <div className="infos">
                        <p><Link to={"/"}>Home</Link></p>
                        <p><Link to={"/services"}>Services</Link></p>
                        <p><Link to={"/gallery"}>Gallery</Link></p>
                        <p><Link to={"/contact"}>Contact</Link></p>
                        <p><Link to={"/blog"}>Blog</Link></p>
                    </div>
                </div>
                <div className="col">
                    <div className="subtitle">
                        <h2>Resources</h2>
                        <hr />
                    </div>
                    <div className="infos">
                        <p><Link to={"/agreement"}>Sales Agreement</Link></p>
                        <p><Link to={"/custom-line-contract"}>Custom Line Contract</Link></p>
                        <p id="warranty-modal-open" style={{cursor: "pointer"}} onClick={toggleModal}>Warranty</p>
                        <p><a href="./" hidden>Site Map</a></p>
                    </div>
                </div>
                <div className="col">
                    <div className="subtitle">
                        <h2>Social</h2>
                        <hr />
                    </div>
                    <div className="infos">
                        <a href="https://instagram.com/rivercitycabinets/" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'instagram', style: 'brands'})} className='icon' /></i> Instagram</a>
                        <a href="https://www.facebook.com/rcc208" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'facebook', style: 'brands'})} className='icon' /></i> Facebook</a>
                        <a href="https://www.pinterest.com/rivercitycabinets/" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'pinterest', style: 'brands'})} className='icon' /></i> Pinterest</a>
                    </div>
                </div>
                <div className="col">
                    <div className="subtitle">
                        <h2>Hours</h2>
                        <hr />
                    </div>
                    <div className="infos mobile">
                        <div className="hours">
                            <p style={{fontWeight: 200}}>Monday - Saturday</p>
                            <p>By appointment only</p>
                        </div>
                        {/* <div className="hours">
                            <p style={{fontWeight: 200}}>Saturdays:</p>
                            <p>By appointment only</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <p className="krosson">Powered by <a className="krosson" href="https://krosson.com" target="_blank" rel="noreferrer">Krosson</a></p>
            <p className="krosson">© Copyright 2023</p>

            {showModal &&
                <div id="warranty-modal" className="warranty-modal">
                    <div className="warranty-modal-content">
                        <div className="warranty-modal-header">
                            <h2>River City Cabinets Warranty</h2>
                            <span className="warranty-modal-close" onClick={toggleModal}>&times;</span>
                        </div>
                        <div className="warranty-modal-body">
                            <p>River City Cabinets warrants, for a period of (3) years (if installed by River City) from the date of delivery to the original purchaser, the product provided by it to be free from defects in material and workmanship when properly installed under normal residential use.</p>
                            <p>This warranty is subject to disclaimers set forth.</p>
                            <p>Some variation in the finishes of these products is characteristic, in terms of color and texture, and does not constitute a defect. Warranty is limited to the original purchaser, for residential applications only.</p>
                            <p>This warranty does not cover defects caused by improper handling, storage, installation, assembly or disassembly that is not provided by River City Cabinets, deliberate damage, product modifications, exposure to the elements including humidity which may result in warping or splitting, accidental misuse, abuse or negligence.</p>
                            <p>Natural woods may vary in texture, color and wood grain, and display subtle changes as they age. For example, wood joints may eventually have visible cracking around the joint area and cabinet colors may darken or lighten over time. Sunlight, smoke, moisture, household cleaners and other environmental conditions may cause materials to vary from their original color and/or to warp, split or crack. These variations are considered to be the nature of the material in relation to their environmental exposure and are not covered under this warranty.</p>
                            <p>River City Cabinets periodically updates and makes changes to its product line and specifications. If a warranty claim is filed against obsolete or changed product, River City Cabinets will replace the part(s) under warranty with a new part of the same style or with a similar style currently offered.</p>
                            <p>River City Cabinets cannot be held responsible for replacement product that may not exactly match installed product. The original consumer purchaser must contact River City Cabinets.</p>
                        </div>
                        <div className="warranty-modal-footer">
                            <h3>Contact us for any question</h3>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Footer;