import './Gallery.css';

import Thumbnail from '../../components/Gallery/Thumbnail';

import { useNavigate } from 'react-router-dom';

const Gallery = props => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <section className="gallery" id="gallery" style={{background: `${props.background}`}}>
            <div className="title">
                <h2>{props.title}</h2>
                <hr />
                <p>Get inspired by our customers' beautiful cabinet projects.</p>
            </div>
            <div className="content">
                {/* <div className="category">
                    <div className="category-name">
                        <h3>Face Framed and Euro Cabinets</h3>
                        <p className="primary">Same Price!</p>
                    </div>
                    <div className="items">
                        <Thumbnail item='face-framed' lightbox={true} />
                        <Thumbnail item='euro-box' lightbox={true} />
                    </div>
                </div> */}
                {/* <div className="category">
                    <div className="category-name">
                        <h3>Framed Door Fronts</h3>
                    </div>
                    <div className="items">
                        <Thumbnail item='antique-cream-door' lightbox={true} door />
                        <Thumbnail item='cognac-door' lightbox={true} door />
                        <Thumbnail item='oxford-door' lightbox={true} door />
                        <Thumbnail item='pebble-stone-door' lightbox={true} door />
                        <Thumbnail item='pure-white-door' lightbox={true} door />
                        <Thumbnail item='shaker-black-door' lightbox={true} door />
                        <Thumbnail item='shaker-cinder-door' lightbox={true} door />
                        <Thumbnail item='shaker-cream-door' lightbox={true} door />
                        <Thumbnail item='shaker-dove-door' lightbox={true} door />
                        <Thumbnail item='toffee-door' lightbox={true} door />
                        <Thumbnail item='torrance-white-door' lightbox={true} door />
                    </div>
                </div> */}
                <div className="category">
                    <div className="category-name">
                        <h3>Face Frame Collection</h3>
                        {/* <div className="underline">
                            <h3>Color</h3> 
                            <hr />
                        </div> */}
                    </div>
                    <div className="items">
                        <Thumbnail item='sage' lightbox={true} badgeText='New!' />
                        <Thumbnail item='oxford' lightbox={true} />
                        <Thumbnail item='toffee-door' lightbox={true} />
                        <Thumbnail item='pigeon-white' lightbox={true} />
                        <Thumbnail item='shaker-cream' lightbox={true} />
                        <Thumbnail item='pure-white' lightbox={true} />
                        <Thumbnail item='shaker-dove' lightbox={true} />
                        <Thumbnail item='pebble-grey' lightbox={true} />
                        <Thumbnail item='pebble-stone' lightbox={true} />
                        <Thumbnail item='shaker-black-door' lightbox={true} />
                        <Thumbnail item='shaker-cinder' lightbox={true} />
                        <Thumbnail item='espresso' lightbox={true} />
                        <Thumbnail item='blue-shaker' lightbox={true} />
                        <Thumbnail item='saddle' lightbox={true} />
                        <Thumbnail item='moonshine' lightbox={true} />
                        <Thumbnail item='torrance-white' lightbox={true} />
                        <Thumbnail item='antique-cream' lightbox={true} />
                        <Thumbnail item='cognac' lightbox={true} />
                    </div>
                </div>
                <div className="category">
                    <div className="category-name">
                        <h3>Frameless Euro Collection</h3>
                        {/* <div className="underline">
                            <h3>Color</h3> 
                            <hr />
                        </div> */}
                    </div>
                    <div className="items">
                        <Thumbnail item='dark-grain' lightbox={true} />
                        <Thumbnail item='dark-grey-gloss' lightbox={true} />
                        <Thumbnail item='glossy-white' lightbox={true} />
                        <Thumbnail item='light-grey' lightbox={true} />
                        <Thumbnail item='white-oak' lightbox={true} />
                    </div>
                </div>
            </div>
            <div className="cta">
                <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
            </div>
        </section>
    )
}

export default Gallery;