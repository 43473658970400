import "./Badge.css";

const Badge = (props) => {
    return (
        <div className="badge-container">
            <div className="shadow">

</div>
            <div className="badge">
                <p>{props.badgeText}</p>
            </div>
            
        </div>
    )
}

export default Badge;